export default [
  {
    path: '/set-amount',
    name: 'amount',
    component: () => import('./pages/amount.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'set amount',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/pagamento',
    name: 'payment',
    component: () => import('./pages/Cart.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/finalizar-pagamento',
    name: 'finishPayment',
    component: () => import('./pages/InstallmentsAndFinish.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Finalizar pedido',
    },
  },
]
