export default {
  // Endpoints speaker to event
  addSpeakerToEvent: 'event/add/speaker',
  editSpeakerToEvent: 'event/edit/speaker',
  deleteSpeakerToEvent: 'event/remove/speaker',
  listAllSpeakerEvent: 'event/speaker/all',
  listAllSpeakers: 'speakers',
  listAllEvents: 'events/per/date/now',
  listAllEventsGet: 'events',

  // statistics
  getStatistics: 'dashboard/statistics',

  // monitoring
  liveMonitoringCreate: 'monitoring/create',
  liveMonitoringUpdate: 'monitoring/update-time',
  liveCloseMonitoring: '',
  // congress
  listCongress: 'congress',

  // rooms
  listRooms: 'eventliveson'
}
