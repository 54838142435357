export default [
  {
    path: '/publicacoes',
    name: 'list_magazine',
    component: () => import('./pages/ListMagazine.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Publicações',
      breadcrumb: [
        {
          text: 'Publicações',
          active: true,
        }
      ],
    },
  },
  {
    path: '/publicacoes/:slug',
    name: 'internal_magazine',
    component: () => import('./pages/InternalMagazine.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Publicações',
      breadcrumb: [
        {
          text: 'Publicações',
          active: true,
        }
      ],
    },
  },
]
