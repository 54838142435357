import * as types from './mutation-types'

export default {
  [types.SET_ORDER](state, payload) {
    state.order = payload
  },
  [types.SET_TOKEN](state, payload) {
    state.token = payload
  },
  [types.SET_INSTALLMENTS](state, payload) {
    state.installments = payload
  },
  [types.SET_ITEMS](state, payload) {
    state.items = payload
  },
  [types.ADD_ITEM](state, payload) {
    state.items.push(payload)
  },
  [types.REMOVE_ITEM](state, payload) {
    state.items.splice(payload, 1)
  },
  [types.SET_CUSTOMER](state, payload) {
    state.customer = payload
  },
  [types.SET_ADDRESS](state, payload) {
    state.address = payload
  },
  [types.SET_PAYMENT](state, payload) {
    state.payment = payload
  },
  [types.SET_TO_GET_INSTALLMENTS](state, payload) {
    state.toGetInstallments = payload
  },
  [types.SET_ORDERS_GN](state, payload) {
    state.ordersGn = payload
  },
}
