export default [
  {
    path: '/podcasts',
    name: 'podcasts',
    component: () => import('./pages/ListLives.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Podcasts',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/podcasts/:slug/',
    name: 'podcast',
    component: () => import('./pages/WatchLive.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
]
