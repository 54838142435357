/* eslint-disable */
import useJwt from '@/auth/jwt'

export default async (to, from, next) => {
  //localStorage.setItem('to', to.name)
  const isLoggedIn = await useJwt.isLoggedIn()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      localStorage.getItem('accessToken') == null ||
      isLoggedIn.data.loggedIn === false
    ) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      const user = JSON.parse(localStorage.getItem('userData'))
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.role === 'ADMIN' || user.role === 'MASTER') {
          next()
        } else {
          next({ path: '/' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (
      localStorage.getItem('accessToken') == null ||
      isLoggedIn.data.loggedIn === false
    ) {
      next()
    } else {
      next({ path: '/' })
    }
  } else {
    next()
  }
}
