import useJwt from '@/modules/magazine/jwt'
import * as types from './mutation-types'

// eslint-disable-next-line import/prefer-default-export

export const ActionListAllMagazine = async ({ dispatch }, payload) => {
  const api = await useJwt.listAllMagazine(payload)
  dispatch('ActionSetMagazine', api.data.response.magazine)
  return api.data.response.magazine
}

export const ActionSetMagazine = async ({ commit }, payload) => {
  commit(types.SET_MAGAZINE, payload)
}
