import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async listAllMagazine(...args) {
    const resp = await axios.get(this.jwtConfig.listAllMagazine, {
      params: {
        ...args[0],
      },
    })
    return resp
  }

  async deleteMagazine(...args) {
    const resp = await axios.post(this.jwtConfig.deleteMagazine, ...args)
    return resp
  }

  async createMagazine(...args) {
    const resp = await axios.post(this.jwtConfig.createMagazine, ...args)
    return resp
  }
}
