export default {
  order: {},
  ordersGn: [],
  token: '',
  installments: '',
  items: [],
  customer: {
    name: '',
    cpf: '',
    email: '',
    phone_number: '',
    birth: '',
    user_id: '',
    birthFormated: '',
  },
  address: {
    street: '',
    number: '',
    neighborhood: '',
    zipcode: '',
    city: '',
    state: '',
  },
  payment: null,
  toGetInstallments: null,
}
