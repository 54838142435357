export default [
  {
    path: '/administrador/personalizar',
    name: 'personalizar',
    component: () => import('./pages/PersonalizarPage.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Personalização',
      breadcrumb: [
        {
          text: 'Administrar personalização',
          active: true,
        },
      ],
    },
  },
]
