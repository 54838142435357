import useJwt from '@/modules/congress/jwt'
import * as types from './mutation-types'

// eslint-disable-next-line import/prefer-default-export
export const ActionLoadCongressAll = async ({ dispatch }) => {
  const api = await useJwt.getAllCongresses()
  dispatch('ActionSetCongressAll', api.data.response.congress)
  return api.data.response.congress
}
export const ActionSetCongressAll = ({ commit }, payload) => {
  commit(types.SET_CONGRESS_ALL, payload)
}

export const ActionLoadCongress = async ({ dispatch }, payload) => {
  console.log(payload)
  const api = await useJwt.getCongresses(payload)
  dispatch('ActionSetCongress', api.data.response.congress)
  return api.data.response.congress
}
export const ActionSetCongress = ({ commit }, payload) => {
  commit(types.SET_CONGRESS, payload)
}

export const ActionLoadEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.getEvent(payload)
  dispatch('ActionSetEvent', api.data.response.event)
  return api.data.response.event
}

export const ActionSetEvent = ({ commit }, payload) => {
  commit(types.SET_EVENT, payload)
}

export const ActionGetEventSpeakerByEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.getEventSpeakerByEvent(payload)
  dispatch('ActionSetEventSpeaker', api.data.response.eventSpeaker)
  return api.data.response.eventSpeaker
}

export const ActionSetEventSpeaker = ({ commit }, payload) => {
  console.log(payload)
  commit(types.SET_EVENT_SPEAKER, payload)
}

export const ActionLoadCurrentActivities = async ({ dispatch }, payload) => {
  const api = await useJwt.getAllCurrentActivities(payload)
  console.log(api.data.response.events)
  dispatch('ActionSetCurrentActivities', api.data.response.events)
  return api.data.response.events
}

export const ActionSetCurrentActivities = ({ commit }, payload) => {
  commit(types.SET_CONGRESS_CURRENT_ACTIVITIES, payload)
}

export const ActionLoadPreviousActivities = async ({ dispatch }, payload) => {
  const api = await useJwt.getAllPreviousActivities(payload)
  dispatch('ActionSetPreviousActivities', api.data.response.events)
  return api.data.response.events
}

export const ActionSetPreviousActivities = ({ commit }, payload) => {
  commit(types.SET_CONGRESS_PREVIOUS_ACTIVITIES, payload)
}

export const ActionCongressAccess = async ({ dispatch }, payload) => {
  const api = await useJwt.congressAccess(payload)
  console.log(api)
  return api
}

export const ActionRegisterUserEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.registerUserEvent(payload)
  return api
}

export const ActionUnregisterUserEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.unregisterUserEvent(payload)
  console.log(api)
  return api
}

export const ActionGetUSerEvents = async ({ dispatch }, payload) => {
  const api = await useJwt.getUserEvent(payload)
  dispatch('ActionSetUserEvents', api.data.response.events)
  return api.data.response.events
}

export const ActionSetUserEvents = ({ commit }, payload) => {
  commit(types.SET_USER_EVENTS, payload)
}

export const ActionGetDeviceId = async ({ dispatch }, payload) => {
  const api = await useJwt.getDeviceId(payload)
  dispatch('ActionSetDeviceId', api.data.response.deviceId)
  return api.data.response.deviceId
}

export const ActionSetDeviceId = ({ commit }, payload) => {
  commit(types.SET_DEVICE_ID, payload)
}

export const ActionSendMessage = async ({ dispatch }, payload) => {
  const api = await useJwt.sendMessage(payload)
  dispatch('ActionGetLiveMessages', payload)

  return api
}

export const ActionGetLiveMessages = async ({ dispatch }, payload) => {
  const api = await useJwt.getLiveMessages(payload)
  dispatch('ActionSetLiveMessages', api.data.response.messages)
  return api
}

export const ActionSetLiveMessages = async ({ commit }, payload) => {
  commit(types.SET_LIVE_MESSAGES, payload)
}

export const ActionGetLiveInfo = async ({ dispatch }, payload) => {
  const api = await useJwt.getLiveInfo(payload)
  return api.data.response.live
}

export const ActionGetEventBySpeaker = async ({ dispatch }, payload) => {
  const api = await useJwt.getEventsBySpeaker()
  dispatch('ActionSetEventBySpeaker', api.data.response.eventSpeaker)
  return api
}

export const ActionSetEventBySpeaker = ({ commit }, payload) => {
  commit(types.SET_SPEAKER_EVENTS, payload)
}

/**
 * SHARE CARD
 */
export const ActionLoadSpeaker = async ({ dispatch }) => {
  const api = await useJwt.getSpeaker()
  dispatch('ActionSetSpeaker', api.data.response.speaker)
  return api
}

export const ActionSetSpeaker = ({ commit }, payload) => {
  commit(types.SET_SPEAKER, payload)
}

export const ActionLoadEventsBlock = async ({ dispatch }, payload) => {
  const api = await useJwt.getEventBlock(payload)
  dispatch('ActionSetEventsBlock', api.data.response.block)
  return api
}

export const ActionSetEventsBlock = ({ commit }, payload) => {
  commit(types.SET_EVENT_BLOCK, payload)
}

export const ActionGetSpeakerBlocksNow = async ({ dispatch }) => {
  const api = await useJwt.speakerBlocksNow()
  dispatch('ActionSetSpeakerBlocksNow', api.data.response.blocks)

  return api
}

export const ActionSetSpeakerBlocksNow = ({ commit }, payload) => {
  commit(types.SET_SPEAKER_BLOCKS_NOW, payload)
}
/**
 * ! SHARE CARD
 */
