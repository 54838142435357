export default [
  {
    path: '/administrador/recording/:lesson_id',
    name: 'Recordingcourses',
    component: () => import('./pages/recording/index.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Gravação da aula',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Cursos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/cursos',
    name: 'courses_admin',
    component: () => import('./pages/crudCourses/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Cursos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Cursos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/modulos/:course_slug/:course_id',
    name: 'courses_admin_modules',
    component: () => import('./pages/crudModules/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Módulos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Cursos',
          active: false,
        },
        {
          text: 'Módulos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/lessons/:module_title/:module_id',
    name: 'courses_admin_modules_lessons',
    component: () => import('./pages/crudLessons/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Aulas',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Cursos',
          active: false,
        },
        {
          text: 'Módulos',
          active: false,
        },
        {
          text: 'Aulas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/aulas/adicional/:lesson_id',
    name: 'courses_admin_modules_lessons_adicional',
    component: () => import('./pages/crudAdditional/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Aulas',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Cursos',
          active: false,
        },
        {
          text: 'Módulos',
          active: false,
        },
        {
          text: 'Aulas',
          active: false,
        },
        {
          text: 'Adicional',
          active: true,
        },
      ],
    },
  },
]
