const array = [
  {
    path: '/',
    name: 'home_plataforma',
    component: () => import('./pages/home.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Inicio',
      breadcrumb: [
        {
          text: 'Inicio',
          active: true,
        },
      ],
    },
  }
]

export default array
