import * as types from './mutation-types'

import useJwt from '@/modules/ranking/jwt'
// eslint-disable-next-line import/prefer-default-export
export const ActionGetUserStats = async ({ dispatch }, payload) => {
  const api = await useJwt.getUserStats()
  dispatch('ActionSetUserStats', api.data.response)
  return api
}

export const ActionSetUserStats = ({ commit }, payload) => {
  commit(types.SET_USER_STATS, payload)
}

export const ActionGetRanking = async ({ dispatch }, payload) => {
  const api = await useJwt.getRanking()
  dispatch('ActionSetRanking', api.data.response.ranking)
  return api
}

export const ActionSetRanking = ({ commit }, payload) => {
  commit(types.SET_RANKING, payload)
}
