import * as types from './mutation-types'

import useJwt from '@/modules/profile/jwt'
// eslint-disable-next-line import/prefer-default-export
export const ActionUpdateUser = async ({ commit }, payload) => {
  const api = await useJwt.updateUser(payload)
  return api
}

export const ActionChangePassword = async ({ dispatch }, payload) => {
  const api = await useJwt.changePassword(payload)
  return api
}
