import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async addSuggestions(...args) {
    const resp = await axios.post(this.jwtConfig.addSuggestions, ...args)
    return resp
  }
}
