const array = [
  {
    path: '/administrador/usuarios',
    name: 'list_users_associate',
    component: () => import('./pages/crud/ListUsers.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Lista de usuários',
      breadcrumb: [
        {
          text: 'Administrador',
          active: true,
        },
        {
          text: 'Usuários',
          active: false,
        },
      ],
    },
  },
  {
    path: '/administrador/usuarios/dashboard',
    name: 'dashboard_users_administrator_associate',
    component: () => import('./pages/crud/Dashboard.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de associados',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'usuarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/usuarios/set-amount',
    name: 'amount_associate',
    component: () => import('./pages/paymentAnuidade/amount.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'set amount',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/administrador/usuarios/pagamento',
    name: 'payment_associate',
    component: () => import('./pages/paymentAnuidade/Cart.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/administrador/usuarios/finalizar-pagamento',
    name: 'finishPayment_associate',
    component: () => import('./pages/paymentAnuidade/InstallmentsAndFinish.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Finalizar pedido',
    },
  },
  {
    path: '/administrador/usuarios/combo/set-amount',
    name: 'amount_associate_combo',
    component: () => import('./pages/paymentCombo/amount.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'set amount',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/administrador/usuarios/combo/pagamento',
    name: 'payment_associate_combo',
    component: () => import('./pages/paymentCombo/Cart.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/administrador/usuarios/combo/finalizar-pagamento',
    name: 'finishPayment_associate_combo',
    component: () => import('./pages/paymentCombo/InstallmentsAndFinish.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Finalizar pedido',
    },
  },
  {
    path: '/administrador/usuarios/congresso/set-amount',
    name: 'amount_associate_congress',
    component: () => import('./pages/paymentCongress/amount.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'set amount',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/administrador/usuarios/congresso/pagamento',
    name: 'payment_associate_congress',
    component: () => import('./pages/paymentCongress/Cart.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/administrador/usuarios/congresso/finalizar-pagamento',
    name: 'finishPayment_associate_congress',
    component: () => import('./pages/paymentCongress/InstallmentsAndFinish.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Finalizar pedido',
    },
  },
]
export default array
