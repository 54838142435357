import * as types from './mutation-types'

export default {
  [types.SET_CONGRESS_ALL](state, payload) {
    state.congressAll = payload
  },
  [types.SET_CONGRESS](state, payload) {
    state.congress = payload
  },
  [types.SET_EVENT](state, payload) {
    state.event = payload
  },
  [types.SET_CONGRESS_CURRENT_ACTIVITIES](state, payload) {
    state.congressCurrentActivities = payload
  },
  [types.SET_CONGRESS_PREVIOUS_ACTIVITIES](state, payload) {
    state.congressPreviousActivities = payload
  },
  [types.SET_EVENT_SPEAKER](state, payload) {
    state.eventSpeaker = payload
  },
  [types.SET_DEVICE_ID](state, payload) {
    state.deviceId = payload
  },
  [types.SET_LIVE_MESSAGES](state, payload) {
    state.liveMessages = payload
  },
  [types.SET_USER_EVENTS](state, payload) {
    state.userEvents = payload
  },
  [types.SET_SPEAKER_EVENTS](state, payload) {
    state.speakerEvents = payload
  },
  [types.SET_SPEAKER](state, payload) {
    state.speaker = payload
  },
  [types.SET_EVENT_BLOCK](state, payload) {
    state.eventBlock = payload
  },
  [types.SET_SPEAKER_BLOCKS_NOW](state, payload) {
    state.speakerBlocksNow = payload
  },
}
