import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async addSpeakerToEvent(...args) {
    const resp = await axios.post(this.jwtConfig.addSpeakerToEvent, ...args)
    return resp
  }

  async editSpeakerToEvent(...args) {
    const resp = await axios.post(this.jwtConfig.editSpeakerToEvent, ...args)
    return resp
  }

  async deleteSpeakerToEvent(...args) {
    const resp = await axios.post(this.jwtConfig.deleteSpeakerToEvent, ...args)
    return resp
  }

  async listAllSpeakerEvent(...args) {
    const resp = await axios.get(this.jwtConfig.listAllSpeakerEvent, ...args)
    return resp
  }

  async listAllEvents(...args) {
    const resp = await axios.get(this.jwtConfig.listAllEvents, {
      params: {
        ...args[0],
      },
    })
    return resp
  }

  async listAllEventsGet(...args) {
    const resp = await axios.get(this.jwtConfig.listAllEventsGet, {
      params: {
        ...args[0],
      },
    })
    return resp
  }

  async listAllSpeakers(...args) {
    const resp = await axios.get(this.jwtConfig.listAllSpeakers, ...args)
    return resp
  }

  async getStatistics(...args) {
    const resp = await axios.get(this.jwtConfig.getStatistics, {
      params: {
        ...args[0],
      },
    })
    return resp
  }

  async liveMonitoringCreate(...args) {
    const resp = await axios.post(this.jwtConfig.liveMonitoringCreate, ...args)
    return resp
  }

  async liveMonitoringUpdate(...args) {
    const resp = await axios.post(this.jwtConfig.liveMonitoringUpdate, ...args)
    return resp
  }

  async listCongress(...args) {
    const resp = await axios.get(this.jwtConfig.listCongress, ...args)
    return resp
  }

  async listRooms(...args) {
    const resp = await axios.get(this.jwtConfig.listRooms, ...args)
    return resp
  }
}
