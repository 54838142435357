import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async getOrder(...args) {
    const resp = await axios.get(this.jwtConfig.getOrderEndpoint, ...args)
    return resp
  }

  async setAmount() {
    const resp = await axios.get(this.jwtConfig.amount)
    return resp
  }
}
