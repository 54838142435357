export default [
  {
    path: '/lives',
    name: 'lives',
    component: () => import('./pages/ListLives.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Lives',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/live/:slug',
    name: 'liveWatch',
    component: () => import('./pages/WatchLive.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/live/info/:slug/:live_id',
    name: 'livesInfo',
    component: () => import('./pages/LiveInfo.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
]
