export default [
    {
      path: '/feed',
      name: 'feed',
      component: () => import('./pages/crud/ListFeed.vue'),
      meta: {
        layout: 'vertical',
        requiresAuth: true,
        pageTitle: 'Feed',
        breadcrumb: [
          // {
          //   text: 'Perfil',
          //   active: true,
          // },
        ],
      },
    },
]