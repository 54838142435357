import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async updateUser(...args) {
    const resp = await axios.post(this.jwtConfig.userUpdateEndpoind, ...args)
    return resp
  }

  async changePassword(...args) {
    const resp = await axios.post(
      this.jwtConfig.changePasswordEndpoint,
      ...args
    )
    return resp
  }
}
