export default [
  {
    path: '/novo/estatuto',
    name: 'statute',
    component: () => import('./pages/Statute.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Novo estatuto',
      breadcrumb: [
        {
          text: 'novo estatuto',
          active: true,
        },
      ],
    },
  },
]
