import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async getUserStats(...args) {
    const resp = await axios.get(this.jwtConfig.userStatsEndpoint, ...args)
    return resp
  }

  async getRanking(...args) {
    const resp = await axios.get(this.jwtConfig.rankingEndpoint, ...args)
    return resp
  }
}
