import * as types from './mutations-types'

export default {
  [types.SET_SESSION_INFO](state, payload) {
    state.sessionInfo = payload
  },
  [types.SET_USER](state, payload) {
    state.user = payload
  },
}
