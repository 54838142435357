import * as types from './mutation-types'

export default {
  [types.SET_SPEAKER_EVENT](state, payload) {
    state.speaker_event = payload
  },
  [types.SET_EVENTS](state, payload) {
    state.events = payload
  },
  [types.SET_EVENTS_GET](state, payload) {
    state.events_list = payload
  },
  [types.SET_SPEAKERS](state, payload) {
    state.speakers = payload
  },
  [types.SET_STATISTICS](state, payload) {
    state.statistics = payload
  },
  [types.SET_CONGRESS](state, payload) {
    state.congress = payload
  },
  [types.SET_ROOMS](state, payload) {
    state.rooms = payload
  },
}
