import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import { store as congress } from '@/modules/congress'
import { store as congressAdministrator } from '@/modules/congressAdministrator'
import { store as payment } from '@/modules/payment'
import { store as ranking } from '@/modules/ranking'
import { store as profile } from '@/modules/profile'
import { store as course } from '@/modules/course'
import { store as magazine } from '@/modules/magazine'
import { store as magazineAdministrator } from '@/modules/magazineAdministrator'

export default {
  app,
  appConfig,
  verticalMenu,
  auth,
  congress,
  congressAdministrator,
  payment,
  ranking,
  profile,
  course,
  magazine,
  magazineAdministrator
}
