const array = [
  {
    path: '/administrador',
    name: 'admin',
    component: () => import('../../views/admin/Admin.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      is_admin: true,
    },
  },
]

export default array
