const array = [
  {
    path: '/associado/combo/11',
    name: 'sdsd',
    component: () => import('./pages/paymentCombo/Cart.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Pedidos',
    },
  },
]
export default array
