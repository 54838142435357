import useJwt from '@/modules/congressAdministrator/jwt'
import * as types from './mutation-types'

// eslint-disable-next-line import/prefer-default-export
export const ActionAddSpeakerToEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.addSpeakerToEvent(payload)
  dispatch('ActionListAllSpeakerEvent')
  return api
}

export const ActionListAllSpeakerEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.listAllSpeakerEvent()
  dispatch('ActionSetSpeakerEvent', api.data.response.event_speaker)
  return api.data.response.event_speaker
}

export const ActionSetSpeakerEvent = async ({ commit }, payload) => {
  commit(types.SET_SPEAKER_EVENT, payload)
}

export const ActionListAllEvents = async ({ dispatch }, payload) => {
  const api = await useJwt.listAllEvents(payload)
  dispatch('ActionSetEvents', api.data.response.events)
  return api.data.response.events
}

export const ActionSetEvents = async ({ commit }, payload) => {
  commit(types.SET_EVENTS, payload)
}

export const ActionListAllSpeakers = async ({ dispatch }) => {
  const api = await useJwt.listAllSpeakers()
  dispatch('ActionSetSpeakers', api.data.response.speakers)
  return api.data.response.speakers
}

export const ActionSetSpeakers = async ({ commit }, payload) => {
  commit(types.SET_SPEAKERS, payload)
}

export const ActionGetStatistics = async ({ dispatch }, payload) => {
  const api = await useJwt.getStatistics(payload)
  dispatch('ActionSetStatistics', api.data.response)
  return api
}

export const ActionSetStatistics = ({ commit }, payload) => {
  commit(types.SET_STATISTICS, payload)
}

export const ActionEditSpeakerToEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.editSpeakerToEvent(payload)
  dispatch('ActionListAllSpeakerEvent')
  return api
}

export const ActionDeleteSpeakerToEvent = async ({ dispatch }, payload) => {
  const api = await useJwt.deleteSpeakerToEvent(payload)
  dispatch('ActionListAllSpeakerEvent')
  return api
}

export const ActionLiveMonitoringCreate = async ({ dispatch }, payload) => {
  const api = await useJwt.liveMonitoringCreate(payload)
  console.log('entrou')
  console.log(api)
  return api
}

export const ActionLiveMonitoringUpdate = async ({ dispatch }, payload) => {
  const api = await useJwt.liveMonitoringUpdate(payload)
  return api
}
