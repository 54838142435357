const array = [
  {
    path: '/administrador/eventos_conf/:id',
    name: 'event_conf',
    props: true,
    component: () => import('./pages/eventConf.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Configurações do evento',
      breadcrumb: [
        {
          text: 'Configurações do evento',
          active: true,
        },
      ],
    },
  },

]

export default array
