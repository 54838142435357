export default [
  {
    path: '/administrador/login',
    name: 'login_administrator',
    component: () => import('./pages/AdmininstarLogin.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Login',
      breadcrumb: [
        {
          text: 'Login',
          active: true,
        },
      ],
    },
  },
]
