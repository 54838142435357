import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import beforeEach from './beforeEach'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.setProperty('display', 'none', 'important')
  }
})

router.beforeEach(beforeEach)

export default router
/*
    appLoading.style.setProperty('display', 'block', 'important')
    appLoading.style.setProperty('opacity', 1, 'important')
    appLoading.style.setProperty('z-index', 5, 'important')
*/
