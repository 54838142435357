const array = [
  {
    path: '/categorias-de-conteudo',
    name: 'contentCategories',
    component: () => import('./pages/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Lista de congressos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: true,
        },
      ],
    },
  },
]

export default array
