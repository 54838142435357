export default [
  {
    path: '/administrador/publicacoes',
    name: 'list_magazine_administration',
    component: () => import('./pages/crudMagazine/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Publicações',
      breadcrumb: [
        {
          text: 'Publicações',
          active: true,
        },
      ],
    },
  },
]
